export const stepTitles = [
    'Erzähl uns etwas über dich',
    'Wann hast du Geburtstag?',
    'Was ist dein Geschlecht?',
    'Was suchst du?',
    'Dein Standort',
    'Registrierung abschließen',
    'Lade ein Profilbild hoch!',
];
export const stepDescription = [
    'Verrate uns einfach deinen Namen.',
    'So können wir dich besser zuordnen.',
    'Damit andere dich leichter finden können.',
    'Wir nutzen diese Information, um dir passende Profile vorzuschlagen.',
    'Wo bist du gerade? Wir verwenden deinen Standort, um dir relevante Profile anzuzeigen.',
    'Du benötigst deine E-Mail, um dich bei Meeted zu registrieren und einzuloggen. Nur du kannst deine E-Mail sehen.',
    'Bitte lade ein Profilbild hoch – mit Bild hast du garantiert bessere Chancen 😉',
];
